// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-guest-book-js": () => import("/opt/build/repo/src/pages/guest-book.js" /* webpackChunkName: "component---src-pages-guest-book-js" */),
  "component---src-pages-highlights-js": () => import("/opt/build/repo/src/pages/highlights.js" /* webpackChunkName: "component---src-pages-highlights-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-js": () => import("/opt/build/repo/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-super-secret-rsvp-js": () => import("/opt/build/repo/src/pages/super-secret-rsvp.js" /* webpackChunkName: "component---src-pages-super-secret-rsvp-js" */),
  "component---src-pages-the-big-day-js": () => import("/opt/build/repo/src/pages/the-big-day.js" /* webpackChunkName: "component---src-pages-the-big-day-js" */),
  "component---src-pages-update-js": () => import("/opt/build/repo/src/pages/update.js" /* webpackChunkName: "component---src-pages-update-js" */)
}

